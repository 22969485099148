<template>
  <div>
    <b-button
      type="submit"
      style="border-radius:11px"
      variant="primary"
      id="show-btn"
      @click="modalShow = !modalShow"
    >
      <span>{{ $t("admin.create_edit_challenge.edit/delete") }}</span>
    </b-button>

    <b-modal
      v-model="modalShow"
      :id="ModalId"
      centered
      hide-footer
      hide-header
      size="lg"
    >
      <b-overlay :show="loading">
        <b-container fluid class="p-4  ">
          <!-- Header Section -->
          <div class="d-flex align-items-center  justify-content-between">
            <h1>
              {{ $t("admin.create_edit_challenge.edit_challenge") }}
            </h1>
            <p
              @click="modalShow = !modalShow"
              style="cursor:pointer; color:#D4D4D4"
            >
              {{ $t("admin.create_edit_challenge.skip") }}
            </p>
          </div>

          <div class="mt-4">
            <b-card class="mb-2" body-class="p-0">
              <div
                style="position:relative;"
                class="mb-3"
                v-if="customChallenge"
              >
                <b-img-lazy
                  class=""
                  v-if="isImageUploadedToS3 && uploaded_image"
                  style="object-fit:cover;width:100%; max-height:250px; border-top-left-radius:15px;border-top-right-radius:15px;"
                  :src="uploaded_image"
                  alt="img"
                />
                <b-img-lazy
                  class=""
                  v-else
                  style="object-fit:cover;width:100%; max-height:250px; border-top-left-radius:15px;border-top-right-radius:15px;"
                  :src="
                    `${customChallenge.file_path}${customChallenge.file_name}`
                  "
                  alt="img"
                />
                <div style="position:absolute; top:5%; right:4%">
                  <b-button
                    variant="primary"
                    size="sm"
                    style="border-radius:10px"
                    @click="PickFile"
                    ><span style="font-size:13px">{{
                      $t("admin.create_edit_challenge.choose_file")
                    }}</span></b-button
                  >
                  <!-- File Input -->
                  <input
                    type="file"
                    style="display:none"
                    ref="InputFile"
                    accept="image/*"
                    @change="uploadFile"
                  />
                </div>
                <div style="position:absolute; bottom:0; right:0">
                  <div
                    class="bg-secondary point-div d-flex flex-column align-items-center "
                  >
                    <span style="font-size:14px" class="pt-2">
                      {{ $t("points") }}
                    </span>
                    <span style="font-size:18px" class="font-weight-bold">
                      {{ Math.trunc(customChallenge.points) }}
                    </span>
                    <b-icon-star
                      class="rounded-circle bg-white p-1 "
                      font-scale="1.2"
                      variant="terciary"
                    ></b-icon-star>
                  </div>
                </div>
              </div>

              <b-row class="mb-4 px-4" v-if="customChallenge">
                <b-col cols="12" class="d-flex">
                  <div>
                    <b-form-input
                      v-if="challengeTitleEdit"
                      v-model="customChallenge.name"
                      type="text"
                      required
                      class="mb-2"
                      :placeholder="$t('admin.create_edit_challenge.add_title')"
                      size="sm"
                    />
                    <p
                      v-else
                      class="text-terciary font-weight-bold"
                      style="cursor:pointer"
                      @click="challengeTitleEdit = true"
                    >
                      {{ this.customChallenge.name }}
                    </p>
                  </div>

                  <div class="ml-3">
                    <b-button
                      class="m-0 p-0 mt-n2 btn shadow-none"
                      style="border:none;background:transparent"
                      @click="challengeTitleEdit = true"
                    >
                      <b-icon-pencil-square
                        font-scale="1.5"
                        class="text-dark"
                      ></b-icon-pencil-square>
                    </b-button>
                  </div>
                </b-col>

                <!-- Challenge Description -->
                <b-col cols="12" class="d-flex align-items-start">
                  <div class="flex-grow-1">
                    <b-form-textarea
                      v-if="challengeDescriptionEdit"
                      v-model="customChallenge.description"
                      type="text"
                      required
                      class="mt-2"
                      size="sm"
                      :placeholder="
                        $t('admin.create_edit_challenge.enter_something')
                      "
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                    <span
                      v-else
                      class=" font-weight-medium"
                      style="font-size:13px; color:#929292; cursor:pointer; white-space: pre-line;"
                      @click="challengeDescriptionEdit = true"
                    >
                      {{ this.customChallenge.description }}
                    </span>
                  </div>

                  <div class="ml-3">
                    <b-button
                      class="m-0 p-0 mt-n2 btn shadow-none"
                      style="border:none;background:transparent"
                      @click="challengeDescriptionEdit = true"
                    >
                      <b-icon-pencil-square
                        font-scale="1.5"
                        class="text-dark"
                      ></b-icon-pencil-square>
                    </b-button>
                  </div>
                </b-col>
                <!-- Challenge short Description -->
                <b-col cols="12" class="mt-4">
                  <div class="d-flex align-items-start">
                    <b-icon-flag-fill class="mt-1" style="color:#E63375" />
                    <p class="ml-3 text-terciary font-weight-medium">
                      {{ $t("missions.challenge") }}:
                    </p>
                  </div>
                  <div class="d-flex align-items-start">
                    <div class="flex-grow-1">
                      <b-form-textarea
                        v-if="challengeExhortionDescriptionEdit"
                        v-model="customChallenge.description_exhortion"
                        type="text"
                        required
                        class="mt-2"
                        size="sm"
                        :placeholder="
                          $t('admin.create_edit_challenge.enter_something')
                        "
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                      <span
                        v-else
                        class=" font-weight-medium"
                        style="font-size:13px; color:#929292; cursor:pointer"
                        @click="challengeExhortionDescriptionEdit = true"
                      >
                        {{ customChallenge.description_exhortion }}
                      </span>
                    </div>

                    <div class="ml-3">
                      <b-button
                        class="m-0 p-0 mt-n2 btn shadow-none"
                        style="border:none;background:transparent"
                        @click="challengeExhortionDescriptionEdit = true"
                      >
                        <b-icon-pencil-square
                          font-scale="1.5"
                          class="text-dark"
                        ></b-icon-pencil-square
                      ></b-button>
                    </div>
                  </div>
                </b-col>

                <b-col
                  style="border-bottom:1px solid #999999;"
                  class="mx-auto mt-4"
                  cols="10"
                ></b-col>
              </b-row>

              <b-row class="px-4" align-v="center">
                <b-col cols="12" sm="12" md="6" lg="6">
                  <b-form-group
                    :label="$t('admin.create_edit_challenge.add_week')"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-select v-model="campaignWeekId" class="mb-3">
                      <b-form-select-option
                        v-for="(week, index) in campaignWeek"
                        :value="week.id"
                        :key="index"
                        >{{ week.text }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                  <b-form-group
                    :label="$t('admin.create_edit_challenge.points_earned')"
                    label-for="pointsEarned"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-select
                      v-model="challengePointId"
                      id="pointsEarned"
                      class="mb-3"
                    >
                      <b-form-select-option
                        v-for="(point, index) in challengePoint"
                        :value="point.id"
                        :key="index"
                        >{{ Math.trunc(point.points) }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                  <b-form-group
                    :label="$t('admin.create_edit_challenge.choose_domain')"
                    label-for="domain"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-select v-model="domainId" id="domain" class="mb-3">
                      <b-form-select-option
                        v-for="(domain, index) in domains"
                        :value="domain.id"
                        :key="index"
                        >{{ domain.domain_name }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                  <b-form-group
                    :label="$t('admin.create_edit_challenge.add_video_url')"
                    label-for="videoUrl"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-input
                      id="videoUrl"
                      v-model="video_url"
                      type="text"
                      class="mb-2"
                      :placeholder="
                        $t('admin.create_edit_challenge.video_url_restriction')
                      "
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="6" lg="6">
                  <b-form-group
                    :label="$t('admin.create_edit_challenge.challenge_tag')"
                    label-for="challenge_tag"
                    class="text-terciary mb-4 font-weight-bold"
                  >
                    <b-form-input
                      id="challenge_tag"
                      v-model="challenge_tag"
                      required
                      type="text"
                      class="mb-2"
                      :placeholder="
                        $t(
                          'admin.create_edit_challenge.challenge_tag-placeholder'
                        )
                      "
                    />
                    <span
                      class="font-weight-normal"
                      style="font-size:13px; color:#929292"
                    >
                      {{ $t("admin.create_edit_challenge.challenge_tag-help") }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mt-3 px-4 mb-4" align-h="end">
                <b-col cols="12" sm="12" md="4" lg="4">
                  <b-button
                    block
                    variant="primary"
                    style="border-radius:11px"
                    @click="EditChallenge"
                    v-if="!spinnerLoading"
                  >
                    {{ $t("admin.create_edit_challenge.save_changes") }}
                  </b-button>
                  <b-button
                    block
                    style="border-radius:11px"
                    variant="primary"
                    disabled
                    v-if="spinnerLoading"
                  >
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </b-button>
                </b-col>
                <b-col cols="12" sm="12" md="4" lg="4" class="margin-md">
                  <b-button
                    block
                    variant="outline-primary"
                    style="border-radius:11px"
                    @click="deleteChallenge"
                    v-if="!deleteSpinnerLoading"
                  >
                    {{ $t("admin.create_edit_challenge.delete_challenge") }}
                  </b-button>
                  <b-button
                    block
                    style="border-radius:11px"
                    variant="primary"
                    disabled
                    v-if="deleteSpinnerLoading"
                  >
                    <b-spinner small></b-spinner>
                    <span class="sr-only">Loading...</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-container>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios"
import { BIconFlagFill, BIconPencilSquare, BIconStar } from "bootstrap-vue"
export default {
  name: "EditDeleteChallengeModal",
  components: { BIconFlagFill, BIconPencilSquare, BIconStar },
  props: ["campaign_id", "challenge_id", "index"],

  data() {
    return {
      loading: false,
      spinnerLoading: false,
      customChallenge: null,
      challengeTitleEdit: false,
      challengeDescriptionEdit: false,
      challengeExhortionDescriptionEdit: false,
      campaignWeekId: "",
      campaignWeek: [],
      challengePointId: "",
      challengePoint: [],
      domainId: "",
      domains: [],
      modalShow: false,
      deleteSpinnerLoading: false,
      isImageUploadedToS3: false,
      SelectedFile: null,
      media_file_id: null,
      uploaded_image: null,
      video_url: "",
      challenge_tag: "",
    }
  },

  computed: {
    ModalId() {
      return this.index.toString()
    },
  },

  methods: {
    ToggleInput() {
      this.challengeTitleEdit = false
      this.challengeDescriptionEdit = false
      this.challengeExhortionDescriptionEdit = false
      this.SelectedFile = null
      this.media_file_id = null
      this.uploaded_image = null
      this.isImageUploadedToS3 = false
    },
    PickFile() {
      this.$refs.InputFile.click()
    },
    async uploadFile(event) {
      var input = event.target
      if (input.files && input.files[0]) {
        this.SelectedFile = input.files[0]

        try {
          let data = {
            filename: this.SelectedFile.name,
            context_type: "COMM",
          }

          const response = await axios.post("/mediaobject", data)

          if (response.status === 200) {
            this.media_file_id = response.data?.data.media_file_id
            this.uploaded_image =
              response.data?.data.filepath + response.data?.data.filename
            const save_URL = response.data?.data.save_url
            // Method to call S3 upload Put Request
            this.uploadFileToS3(save_URL)
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        }
      }
    },
    async uploadFileToS3(url) {
      this.loading = true
      try {
        const response = await fetch(url, {
          method: "PUT",
          body: this.SelectedFile,
          headers: {
            "Content-Type": this.SelectedFile.type,
          },
        })

        if (response.status === 200) {
          this.isImageUploadedToS3 = true
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getOrgCustomChallenge(campaign_id, challenge_id) {
      this.loading = true
      try {
        let data = {
          customer_campaign_instance_id: campaign_id,
          challenge_id: challenge_id,
        }

        const response = await axios.get(`/admin/customchallenge`, {
          params: data,
        })

        if (response.status === 200) {
          this.customChallenge = response.data?.data[0] ?? null
          if (
            this.customChallenge.live_media_file_path &&
            this.customChallenge.live_file_name
          ) {
            this.video_url =
              this.customChallenge.live_media_file_path +
              this.customChallenge.live_file_name
          } else {
            this.video_url = ""
          }
          this.challenge_tag = this.customChallenge?.challenge_tag || ""
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getCampaignWeek(campaign_id, challenge_id) {
      this.loading = true
      try {
        let data = {
          customer_campaign_instance_id: campaign_id,
          challenge_id: challenge_id,
        }

        const response = await axios.get(`/admin/getcampaignweeks`, {
          params: data,
        })

        if (response.status === 200) {
          this.campaignWeek = response.data?.data ?? null
          this.campaignWeek.forEach(week => {
            if (week.selected === "Y") {
              this.campaignWeekId = week.id
            }
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengePoint(campaign_id, challenge_id) {
      this.loading = true
      try {
        let data = {
          customer_campaign_instance_id: campaign_id,
          challenge_id: challenge_id,
        }

        const response = await axios.get(`/admin/getcustomchallengepoints`, {
          params: data,
        })

        if (response.status === 200) {
          this.challengePoint = response.data?.data ?? null
          this.challengePoint.forEach(point => {
            if (point.selected === "Y") {
              this.challengePointId = point.id
            }
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async getChallengeDomains(campaign_id, challenge_id) {
      this.loading = true
      try {
        let data = {
          customer_campaign_instance_id: campaign_id,
          challenge_id: challenge_id,
        }

        const response = await axios.get(`/admin/getcustomchallengedomains`, {
          params: data,
        })

        if (response.status === 200) {
          this.domains = response.data?.data ?? null
          this.domains.forEach(domain => {
            if (domain.selected === "Y") {
              this.domainId = domain.id
            }
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
    async EditChallenge() {
      this.spinnerLoading = true
      try {
        let data = {
          customer_campaign_instance_id: this.campaign_id,
          challenge_title: this.customChallenge.name,
          challenge_description: this.customChallenge.description,
          challenge_description_exhortion: this.customChallenge
            .description_exhortion,
          challenge_week: this.campaignWeekId,
          challenge_domain: this.domainId,
          challenge_id: this.challenge_id,
          activity_id: this.challengePointId,
        }
        if (this.video_url !== "") {
          data["video_url"] = this.video_url
        }
        if (this.challenge_tag !== "") {
          data["challenge_tag"] = this.challenge_tag
        }
        if (this.customChallenge.media_file_id && !this.media_file_id) {
          data["media_file_id"] = this.customChallenge.media_file_id
        } else {
          data["media_file_id"] = this.media_file_id
        }
        const response = await axios.post("/admin/customchallenge", data)
        if (response.status === 200) {
          // Close Modal
          this.modalShow = !this.modalShow
          this.$emit("getCustomChallenges")
          this.$store.dispatch("addToastMessage", {
            type: "success",
            text: this.$t("admin.create_edit_challenge.challenge_edited"),
          })
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.spinnerLoading = false
      }
    },
    async deleteChallenge() {
      this.deleteSpinnerLoading = true
      try {
        let data = {
          customer_campaign_instance_id: this.campaign_id,
          challenge_id: this.challenge_id,
        }
        const response = await axios.delete("/admin/customchallenge", {
          params: data,
        })
        if (response.status === 200) {
          // Close Modal
          this.modalShow = !this.modalShow
          this.$emit("getCustomChallenges")
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.deleteSpinnerLoading = false
      }
    },
  },

  mounted() {
    this.$root.$on("bv::modal::show", modalId => {
      if (modalId.componentId === this.index.toString()) {
        if (this.campaign_id && this.challenge_id) {
          this.ToggleInput()
          this.getOrgCustomChallenge(this.campaign_id, this.challenge_id)
          this.getCampaignWeek(this.campaign_id, this.challenge_id)
          this.getChallengePoint(this.campaign_id, this.challenge_id)
          this.getChallengeDomains(this.campaign_id, this.challenge_id)
        }
      }
    })
  },
}
</script>

<style scoped>
div >>> #edit-modal___BV_modal_content_ {
  border-radius: 15px;
  border: none;
}
.point-div {
  width: 4rem;
  height: 5.2rem;
  border-top-left-radius: 10px;
  color: #ffffff;
}

@media (max-width: 770px) {
  .margin-md {
    margin-top: 20px !important;
  }
}
</style>
