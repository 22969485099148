<template>
  <div :style="{ height: '300px' }" ref="chartdiv"></div>
</template>

<script>
import {
  useTheme,
  create,
  color,
  percent,
  addLicense,
} from "@amcharts/amcharts4/core"
import {
  ColumnSeries,
  ValueAxis,
  CategoryAxis,
  XYChart,
} from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

if (process.env.VUE_APP_AMCHARTS4_LICENSE)
  addLicense(process.env.VUE_APP_AMCHARTS4_LICENSE)

useTheme(am4themes_animated)

export default {
  props: ["chartData"],
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    let chart = create(this.$refs.chartdiv, XYChart)

    chart.data = this.chartData

    let categoryAxis = chart.xAxes.push(new CategoryAxis())
    categoryAxis.dataFields.category = "level_description"
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.labels.template.wrap = true
    categoryAxis.renderer.labels.template.maxWidth = 120
    categoryAxis.renderer.labels.template.stroke = color("#545454")
    categoryAxis.autoGridCount = false
    categoryAxis.renderer.minGridDistance = 0

    //     categoryAxis.renderer.cellStartLocation = 0;
    // categoryAxis.renderer.cellEndLocation = 1;

    let valueAxis = chart.yAxes.push(new ValueAxis())

    valueAxis.renderer.inside = false
    valueAxis.renderer.labels.template.fillOpacity = 0.5
    valueAxis.title.fontWeight = "bold"
    valueAxis.title.fill = color("#545454")
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.cursorTooltipEnabled = false
    valueAxis.includeRangesInMinMax = true
    valueAxis.renderer.gridContainer.zIndex = 1
    valueAxis.renderer.labels.template.adapter.add("text", label => {
      return label + "%"
    })

    let series = chart.series.push(new ColumnSeries())

    series.dataFields.valueY = "percentage_of_users_that_improved"
    series.columns.template.width = percent(60)

    series.dataFields.categoryX = "level_description"

    chart.colors.list = [color("#E63375"), color("#00C782"), color("#F0E07D")]
    series.columns.template.events.once("inited", function(event) {
      event.target.fill = chart.colors.getIndex(event.target.dataItem.index)
      event.target.stroke = chart.colors.getIndex(event.target.dataItem.index)
    })
    chart.responsive.enabled = true
    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style></style>
