<template>
  <div v-if="this.campaign_id">
    <b-overlay :show="loading">
      <!-- Top Section -->
      <b-row class="mt-2">
        <b-col cols="12" sm="12" md="8" lg="8">
          <p class="text-terciary font-weight-bold">
            {{ $t("admin.want_to_create_challenges") }}
          </p>
        </b-col>
        <b-col cols="12" sm="12" md="8" lg="8">
          <p style="color:#656c78" class="mt-2">
            {{ $t("admin.want_to_create_challenges-text") }}
          </p>
          <p style="color:#656c78" class="mt-2">
            {{ $t("admin.image_tip") }}
            <a href="https://unsplash.com/" rel="noreferrer" target="_blank">
              Unsplash</a
            >
            &
            <a href="https://pixabay.com/" rel="noreferrer" target="_blank">
              Pixabay </a
            >.
          </p>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="12" md="3" lg="3">
          <CreateChallengeModal
            :campaign_id="campaign_id"
            @getCustomChallenges="getOrgCustomChallenge()"
          />
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col cols="12">
          <p class="text-terciary font-weight-bold">
            {{ $t("admin.custom_challenges") }}
          </p>
        </b-col>
        <b-col cols="12">
          <b-table
            v-if="OrgCustomChallenge"
            :items="OrgCustomChallenge"
            :fields="fields"
          >
            <!-- Week_no Template -->
            <template #cell(week_no)="data">
              <span>{{ $t("week") }} {{ data.item.week_no }}</span>
            </template>

            <!-- Challenge Card ttemplate -->
            <template #cell(challenge)="data">
              <b-card class="" style="max-width: 500px;" body-class="p-0">
                <div style="position:relative;" class="mb-3">
                  <b-img-lazy
                    class=""
                    fluid
                    style="object-fit:cover;width:100%;max-height:156px;height:156px; border-top-left-radius:15px;border-top-right-radius:15px;"
                    :src="`${data.item.file_path}${data.item.file_name}`"
                    alt="img"
                  />
                  <div style="position:absolute; bottom:0; right:0">
                    <div
                      class="bg-secondary point-div d-flex flex-column align-items-center"
                    >
                      <span style="font-size:14px" class="pt-2">
                        {{ $t("points") }}
                      </span>
                      <span style="font-size:18px" class="font-weight-bold">
                        {{ data.item.points }}
                      </span>

                      <b-icon-star
                        class="rounded-circle bg-white p-1 "
                        font-scale="1.2"
                        variant="terciary"
                      ></b-icon-star>
                    </div>
                  </div>
                </div>

                <b-row class="px-3 pb-4" no-gutters>
                  <b-col cols="12" class="d-flex ">
                    <p class="text-terciary font-weight-bold flex-grow-1">
                      {{ data.item.name }}
                    </p>
                    <div v-if="data.item.description.length > 180">
                      <b-button
                        class="m-0 p-0 btn shadow-none"
                        style="background:transparent;border:none"
                        @click="readMore = !readMore"
                      >
                        <span class="text-pink font-weight-bold">
                          <span v-if="!readMore">
                            {{ $t("read_more") }}
                            <b-icon-chevron-down
                              class="mt-1 text-pink font-weight-bold"
                          /></span>
                          <span v-else>
                            {{ $t("read_less") }}
                            <b-icon-chevron-up
                              class="mt-1 text-pink font-weight-bold"/></span
                        ></span>
                      </b-button>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="text-right"></div>
                    <div v-if="data.item.description.length > 180 && !readMore">
                      <p
                        class="font-weight-medium"
                        style="color:#92929; white-space: pre-line;"
                      >
                        {{ data.item.description.slice(0, 180) }}...
                      </p>
                    </div>
                    <div v-else>
                      <p
                        class="font-weight-medium "
                        style="color:#92929; white-space: pre-line;"
                      >
                        {{ data.item.description }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex align-items-start">
                      <b-icon-flag-fill class="mt-1" style="color:#4d4b8a" />
                      <p class="ml-3 text-terciary font-weight-medium">
                        {{ $t("missions.challenge") }}:
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <p class="font-weight-medium" style="color:#92929">
                      {{ data.item.description_exhortion }}
                    </p>
                  </b-col>
                </b-row>
              </b-card>
            </template>

            <!-- Edit/Delete Button Template -->
            <template #cell(edit)="data">
              <EditDeleteChallengeModal
                :campaign_id="campaign_id"
                :challenge_id="data.item.challenge_id"
                @getCustomChallenges="getOrgCustomChallenge()"
                :index="data.index"
              />
            </template>
          </b-table>
          <p class="text-terciary" v-else>
            {{ $t("admin.select_campaign_period") }}
          </p>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
  <div v-else>
    <b-row class="mt-2">
      <b-col cols="12">
        <p class="text-terciary">{{ $t("admin.select_campaign_period") }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BIconFlagFill,
  BIconChevronDown,
  BIconChevronUp,
  BIconStar,
} from "bootstrap-vue"
import axios from "axios"
import CreateChallengeModal from "@/components/Admin/CreateChallengeModal.vue"
import EditDeleteChallengeModal from "@/components/Admin/EditDeleteChallengeModal.vue"
export default {
  name: "OwnChallengesTab",
  components: {
    CreateChallengeModal,
    BIconFlagFill,
    EditDeleteChallengeModal,
    BIconChevronDown,
    BIconChevronUp,
    BIconStar,
  },
  props: ["campaign_id"],
  data() {
    return {
      loading: false,
      readMore: false,
      OrgCustomChallenge: null,
      fields: [
        {
          key: "name",
          label: this.$t("admin.challenge_name"),
        },
        {
          key: "week_no",
          label: this.$t("admin.available_week"),
        },
        {
          key: "no_users",
          label: this.$t("admin.participants"),
        },
        { key: "challenge", label: this.$t("missions.challenge") },
        { key: "edit", label: this.$t("admin.edit") },
      ],
    }
  },
  watch: {
    campaign_id() {
      this.getOrgCustomChallenge(this.campaign_id)
    },
  },
  methods: {
    async getOrgCustomChallenge(id) {
      this.loading = true
      let data
      try {
        if (id) {
          data = {
            customer_campaign_instance_id: id,
          }
        } else {
          data = {
            customer_campaign_instance_id: this.campaign_id,
          }
        }

        const response = await axios.get(`/admin/getourchallenges`, {
          params: data,
        })

        if (response.status === 200) {
          this.OrgCustomChallenge = response.data?.data ?? null
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.point-div {
  width: 4rem;
  height: 5.2rem;
  border-top-left-radius: 10px;
  color: #ffffff;
}
</style>
