<template>
  <b-container class="my-2 my-sm-5" fluid>
    <h1>{{ $t("admin.pagename") }}</h1>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="loading">
          <div v-if="adminCampaign" style="max-width:300px; width: 100%">
            <p class="m-0 text-terciary mb-1 font-weight-bold">
              {{ $t("campaign_period") }}
            </p>
            <b-form-select v-model="selected" size="md">
              <b-form-select-option
                v-for="campaign in adminCampaign"
                :value="campaign.customer_campaign_instance_id"
                :key="campaign.customer_campaign_instance_id"
              >
                {{ campaign.valid_from_dt }} - {{ campaign.valid_to_dt }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- Admin Section -->
    <div class="mt-3 mb-2">
      <!-- Admin Tabs -->
      <b-row class="mt-4 ">
        <b-tabs content-class="mt-4" class="tabs-clear my-3" style="width:100%">
          <!-- Users Tab -->
          <b-tab :title="$t('admin.users')" active>
            <b-row no-gutters>
              <b-col cols="12">
                <UsersTab :campaign_id="selected" />
              </b-col>
            </b-row>
          </b-tab>

          <!-- Statistics Tab -->
          <b-tab :title="$t('admin.statistics')">
            <b-row no-gutters>
              <b-col cols="12">
                <StatsTab :campaign_id="selected" />
              </b-col>
            </b-row>
          </b-tab>

          <!-- Our Own Challenges Tab -->
          <b-tab :title="$t('admin.own_challenges')">
            <b-row class="mt-4">
              <b-col cols="12">
                <OwnChallengesTab :campaign_id="selected" />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import axios from "axios"
import UsersTab from "@/components/Admin/UsersTab.vue"
import StatsTab from "@/components/Admin/StatsTab.vue"
import OwnChallengesTab from "@/components/Admin/OwnChallengesTab.vue"
export default {
  components: {
    UsersTab,
    StatsTab,
    OwnChallengesTab,
  },

  data() {
    return {
      loading: false,
      adminCampaign: null,
      selected: null,
    }
  },
  methods: {
    async getUserCampaign() {
      this.loading = true
      try {
        const response = await axios.get(`/admin/campaigns`)
        if (response.status === 200) {
          this.adminCampaign = response.data?.data ?? null
          this.selected = this.adminCampaign[0].customer_campaign_instance_id
        }
      } catch ({ response }) {
        this.$store.dispatch("addToastMessage", {
          type: "danger",
          text: response.data.error_message,
        })
      } finally {
        this.loading = false
      }
    },
  },
  created() {
    this.getUserCampaign()
  },
}
</script>
