<template>
  <div>
    <b-overlay :show="loading">
      <div v-if="campaignUsers && columnValues">
        <b-table responsive :items="columnValues" :fields="fields"></b-table>
        <!-- Functions trigger when user reaches bottom of the div -->
        <div
          v-if="campaignUsers.length"
          v-observe-visibility="ScrolledToBottom"
        ></div>
      </div>

      <div v-else>
        <b-row class="mt-2">
          <b-col cols="12">
            <p class="text-terciary">
              {{ $t("admin.select_campaign_period") }}
            </p>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios from "axios"
export default {
  props: ["campaign_id"],
  data() {
    return {
      fields: [
        {
          key: "full_name",
          label: this.$t("name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.$t("email-short"),
          sortable: true,
        },

        {
          key: "steps_total",
          label: this.$t("admin.steps_total"),
          sortable: true,
        },
        {
          key: "daily_steps_avg",
          label: this.$t("myhealth.average_steps"),
          sortable: true,
        },
        {
          key: "points_total",
          label: this.$t("admin.points_total"),
          sortable: true,
        },
        {
          key: "completed_challenges",
          label: this.$t("admin.completed_challenges"),
          sortable: true,
        },
        {
          key: "unit",
          label: this.$t("admin.unit"),
          sortable: true,
        },
        {
          key: "team",
          label: this.$t("admin.team"),
          sortable: true,
        },
      ],
      loading: false,
      campaignUsers: null,
      links: null,
    }
  },
  watch: {
    campaign_id() {
      this.getCampaignUsers(this.campaign_id)
    },
  },
  computed: {
    /** @returns {array} */
    columnValues() {
      let arrValue = []
      if (this.campaignUsers) {
        arrValue = this.campaignUsers.map(v => ({
          full_name: v.first_name + " " + v.last_name,
          email: v.email,
          unit: v.organizational_unit,
          team: v.team_name,
          steps_total: v.selected_user_campaign.total_steps,
          daily_steps_avg: v.selected_user_campaign.daily_step_average,
          points_total: v.selected_user_campaign.total_points,
          completed_challenges: v.selected_user_campaign.completed_challenges,
        }))
      }
      return arrValue
    },
  },
  methods: {
    async getCampaignUsers(id) {
      if (id) {
        this.loading = true

        try {
          let data = {
            customer_campaign_instance_id: id,
          }

          const response = await axios.get(`/admin/users`, {
            params: data,
          })

          if (response.status === 200) {
            this.campaignUsers = response.data?.data ?? null
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    async getCampaignUsersPaginated(id, pageURL) {
      if (id && pageURL) {
        this.loading = true

        try {
          let data = {
            customer_campaign_instance_id: id,
          }

          const response = await axios.get(pageURL, {
            params: data,
          })

          if (response.status === 200) {
            this.campaignUsers = [
              ...this.campaignUsers,
              ...(response.data?.data ?? []),
            ]
            this.links = response.data?.links ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.item.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },

    ScrolledToBottom(isVisible) {
      if (!isVisible) {
        return
      }
      if (this.links.more_pages) {
        this.getCampaignUsersPaginated(
          this.campaign_id,
          this.links.next_page_url
        )
      }
    },
  },
}
</script>

<style></style>
