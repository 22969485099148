<template>
  <div v-if="this.campaign_id">
    <b-overlay :show="loading">
      <div v-if="AdminStats">
        <b-row
          class="stats-wrapper pb-5 mt-2 "
          style="border-bottom: 2px solid #dcdcdc;"
        >
          <b-col
            v-for="(stat, index) in AdminStats.kpis"
            :key="index"
            cols="12"
            sm="12"
            md="3"
            lg="3"
            v-show="stat.kpi_name !== 'Users Giving Feedback'"
          >
            <div class="stats-box text-center d-flex flex-column font-weight-semibold">
              <div style="opacity:0.8" :style="{ background: headerBG(stat.kpi_name) }">
                <p class="m-0 text-center">{{ stat.kpi_category }}</p>
              </div>
              <div
                :style="{ background: bodyBG(stat.kpi_name) }"
                class="d-flex justify-content-center py-3 align-items-center"
              >
                <h1 class="text-white">{{ stat.kpi_value }}</h1>
              </div>
              <div style="opacity:0.9" :style="{ background: footerBG(stat.kpi_name) }">
                <p class="m-0 text-center">{{ stat.kpi_name }}</p>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="mt-5">
          <b-row>
            <b-col cols="12">
              <p class="text-terciary font-weight-bold">
                {{ $t("admin.users_giving_feedback") }}:
                {{ getUserFeedbackKPI }}
              </p>
            </b-col>
          </b-row>

          <b-row
            v-for="(improvement_stat,
            index) in AdminStats.improvement_statistics"
            :key="index"
            class="mb-5"
          >
            <b-col cols="12" class="mt-4">
              <h3 class="text-terciary">
                {{ index + 1 }}. {{ improvement_stat.title }}
              </h3>
            </b-col>
            <b-col cols="12" md="9" lg="9" class="">
              <span class="text-muted">
                {{ improvement_stat.description }}
              </span>
            </b-col>
            <b-col cols="12" sm="12" md="10" lg="6" class="mt-3">
              <div style="">
                <StatsChart :chartData="improvement_stat.results" />
              </div>
            </b-col>
            <b-col class="my-auto">
              {{ improvement_stat.statistical_description }}
            </b-col>
          </b-row>
        </div>
      </div>
    </b-overlay>
  </div>
  <div v-else>
    <b-row class="mt-2">
      <b-col cols="12">
        <p class="text-terciary">{{ $t("admin.select_campaign_period") }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// TODO: Move away from hardcoded keys

import axios from "axios"
import StatsChart from "@/components/Admin/StatsChart.vue"
export default {
  props: ["campaign_id"],
  components: {
    StatsChart,
  },
  data() {
    return {
      AdminStats: null,
      loading: false,
    }
  },
  watch: {
    campaign_id() {
      this.getAdminStatistics(this.campaign_id)
    },
  },
  computed: {
    getUserFeedbackKPI() {
      let value
      this.AdminStats.kpis.forEach(kpi => {
        if (kpi.kpi_name === "Users Giving Feedback") {
          value = kpi.kpi_value
        }
      })
      return value
    },
  },
  methods: {
    async getAdminStatistics(id) {
      if (id) {
        this.loading = true
        try {
          let data = {
            customer_campaign_instance_id: id,
          }

          const response = await axios.get(`/admin/statistics`, {
            params: data,
          })

          if (response.status === 200) {
            this.AdminStats = response.data?.data ?? null
          }
        } catch ({ response }) {
          this.$store.dispatch("addToastMessage", {
            type: "danger",
            text: response.data.error_message,
          })
        } finally {
          this.loading = false
        }
      }
    },
    headerBG(value) {
      if (value == "Total Number Of Users") return "#E63375"
      if (value == "Users Giving Feedback") return "#97d161"
      if (value == "Total Kilometers") return "#00C782"
      if (value == "Accepted Challenges") return "#B0BFBF"
      if (value == "Completed Challenges") return "#545454"
      if (value == "Social Posts") return "#00C782"
      if (value == "Reactions (likes etc)") return "#E63375"
    },
    bodyBG(value) {
      if (value == "Total Number Of Users") return "#E63375"
      if (value == "Users Giving Feedback") return "#87c644"
      if (value == "Total Kilometers") return "#00C782"
      if (value == "Accepted Challenges") return "#B0BFBF"
      if (value == "Completed Challenges") return "#545454"
      if (value == "Social Posts") return "#00C782"
      if (value == "Reactions (likes etc)") return "#E63375"
    },
    footerBG(value) {
      if (value == "Total Number Of Users") return "#E63375"
      if (value == "Users Giving Feedback") return "#719833"
      if (value == "Total Kilometers") return "#00C782"
      if (value == "Accepted Challenges") return "#B0BFBF"
      if (value == "Completed Challenges") return "#545454"
      if (value == "Social Posts") return "#00C782"
      if (value == "Reactions (likes etc)") return "#E63375"
    },
  },
}
</script>

<style scoped>
.stats-box {
  width: 100%;
  height: 150px;
  max-height: 150px;
  color: #ffffff;
}
</style>
